import LineGraph from "./LineGraph";
import Filter from "components/shared/Filter";
import { capitalizeFirstLetter, currencyFormatter, dateFormatter } from "utils/helpers";
import Table from "components/Table";
import viewmore_dropdown from "images/svg/recova/viewmore_dropdown.svg";
import { useEffect, useState } from "react";
import clsx from "clsx";
import { RecovaService } from "services/recova.service";
import { useSearchParams } from "react-router-dom";
import Status from "components/Status";


export default function MandateSection () {
  const [dropDownView, setDropDownView] = useState(null)
  const [graphData, setGraphData] = useState([])
  const [mandates, setMandates] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get("page") ?? 1
  const search = searchParams.get("search")
  const status = searchParams.get("status")
  const [breakdown, setBreakdown] = useState(null)
  const [monoStatus, setMonoStatus] = useState("")

  useEffect(()=>{
    searchParams.set("search", "");
    searchParams.set("page", 1);
    searchParams.set("status", "");
    setSearchParams(searchParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  useEffect(()=>{
    setIsLoading(true)
    RecovaService.getMandates(
      page, 
      search, 
      status==="all" ? "" : (status||"")
    )
      .then((response) => response.data)
      .then((res) => {
          setIsLoading(false)
          setMandates(res.data.businessMandates)
          const formattedGraphData = Object.entries(res.data.graphData).map(([daysRange, count]) => ({
            daysRange,
            count
          }));
          setGraphData(formattedGraphData)
      })
      .catch((err) => {
        setIsLoading(false)
      })
  },[page, search, status])

  function getMonthsBetweenDates(date1_, date2_) {
    let date1 = new Date(date1_)
    let date2 = new Date(date2_)
    if (date1 > date2) {
      let temp = date1;
      date1 = date2;
      date2 = temp;
    }
    
    let yearsDifference = date2?.getFullYear() - date1?.getFullYear();
    let monthsDifference = date2?.getMonth() - date1?.getMonth();
  
    return yearsDifference * 12 + monthsDifference;
  }

  useEffect(()=>{
    if(!!dropDownView){
      RecovaService.getMandateBreakdown(dropDownView._id)
        .then((response) => response.data)
        .then((res) => {
          if(res.status===true){
            setBreakdown(res.data)
          }
        })
        .catch((err) => {})
      RecovaService.getMandateMonoStatus(dropDownView._id)
        .then((response) => response.data)
        .then((res) => {
          setMonoStatus(res.data[0].data)
        })
        .catch((err) => {})
    }
  }, [dropDownView])

  return (
      <div>
          <div>
              <h1 className="text-2xl font-bold">Mandates</h1>
              <LineGraph data={graphData} graphFor="home" cartesianGridType="dottedXonly"/>
              <div className="my-10">
                  <Table
                      tableTitle={"Mandate Activities from Businesses"}
                      hideSearch={false}
                      data={mandates?.results || []}
                      backendPaginated={true}
                      totalPages={mandates?.totalPages ?? 0}
                      isLoading={isLoading}
                      searchPlaceHolder="Search by reference..."
                      onSearchChange={(e)=>{
                        searchParams.set("search", e?.target?.value);
                        searchParams.set("page", 1);
                        setSearchParams(searchParams);
                      }}
                      slot={
                        <div>
                          <Filter 
                            keyName="status"
                            options={[
                                { label: "All", value: "all" },
                                { label: "Pending", value: "pending" },
                                { label: "Active", value: "active" },
                                { label: "Paused", value: "paused" },
                                { label: "Deactivated", value: "deactivated" },
                              ]} 
                          />
                        </div>
                      }
                      theadClass="font-semibold text-[#667085] text-xs"
                      tdataClass="py-2.5 pl-[10px] text-sm"
                      rows={[
                          {
                              header:'Business Name',
                              view: (item) => (
                                  <span>
                                      {item?.businessId?.businessName}
                                  </span>
                              ),
                          },
                          {
                              header:'Amount Due',
                              view: (item) => (
                                  <span>
                                      {currencyFormatter(item?.totalAmountDue||0)}
                                  </span>
                              ),
                          },
                          {
                              header:'Duration',
                              view: (item) => (
                                  <span>{getMonthsBetweenDates(item?.startDate, item?.endDate)} month(s)</span>
                              ),
                          },
                          {
                              header:'Created At',
                              view: (item) => (
                                  <span>
                                      {dateFormatter(item.createdAt, false)}
                                  </span>
                              ),
                          },
                          {
                              header:'Start Date',
                              view: (item) => (
                                  <span>{dateFormatter(item?.startDate, false)}</span>
                              ),
                          },
                          {
                              header:'End Date',
                              view: (item) => (
                                  <span>{dateFormatter(item?.endDate, false)}</span>
                              ),
                          },
                          {
                              header:'Collection Mode',
                              view: (item) => (
                                  <span className="capitalize">{item?.collectionMode}</span>
                              ),
                          },
                          {
                              header:'Status',
                              view: (item) => (
                                <div className="flex items-center text-sm pl-2.5">
                                    <div 
                                        className={clsx(
                                            "w-2.5 h-2.5 rounded-full",
                                            item?.status==="pending" ? "bg-[#FEC84B]" : item?.status==="completed"||item?.status==="active" ? "bg-[#2BBD35]" : "bg-[#F04438]"
                                        )}
                                    />
                                    <p 
                                        className={clsx(
                                            "ml-1.5 font-bold",
                                            item?.status==="pending" ? "text-[#FEC84B]" : item?.status==="completed"||item?.status==="active" ? "text-[#2BBD35]" : "text-[#F04438]"
                                        )}
                                    >{item?.status}</p>
                                </div>
                              ),
                          },
                          {
                            header: "",
                            view: (item) => (
                              <div>
                                <img 
                                  src={viewmore_dropdown} 
                                  alt="" 
                                  onClick={()=>{
                                    if(!!dropDownView){
                                      setDropDownView(null)
                                      setBreakdown(null)
                                      setMonoStatus(null)
                                    } else {
                                      setDropDownView(item)
                                    }
                                  }}
                                  className={clsx(
                                    "w-8 cursor-pointer transition-all",
                                    item===dropDownView ? "rotate-180":"rotate-0",
                                  )}
                                />
                                {
                                  item===dropDownView &&
                                  <div className="absolute w-full left-0 z-10">
                                    <div className="shadow-md bg-white rounded mx-6 my-[10px] border-[1px] text-sm border-[#0349FD] p-[20px]">
                                      <div className="grid grid-cols-5 gap-[20px]">
                                        {
                                          [
                                            {
                                              title: "Consent ID",
                                              value: item?.consents?.[0]?._id||item?.consents?.[0]||"---",
                                            },
                                            {
                                              title: "eMandate Reference",
                                              value: item?.reference||"---",
                                            },
                                            {
                                              title: "Product Name",
                                              value: item?.productId?.name ?? "---",
                                            },
                                            {
                                              title: "Mandate Type",
                                              value: item?.mandateType ?? "---",
                                            },
                                            {
                                              title: "Advanced Notice Sent",
                                              value: "True",
                                            },
                                            {
                                              title: "Collection Mode",
                                              value: item?.collectionMode||"---",
                                            },
                                            {
                                              title: "BVN",
                                              value: item?.bvn||"---",
                                            },
                                            {
                                              title: "Mandate Status",
                                              value: item?.status||"---",
                                            },
                                            {
                                              title: "Credit Insurance Enabled",
                                              value: item?.creditInsurance ? "True":"False",
                                            },
                                            {
                                              title: "Account Name",
                                              value: "---",
                                            },
                                            {
                                              title: "Payer's Address",
                                              value: "---",
                                            },
                                            {
                                              title: "Payer's Bank",
                                              value: "---",
                                            },
                                            {
                                              title: "Account Number",
                                              value: "---",
                                            },
                                            {
                                              title: "Payment Type",
                                              value: "---",
                                            },
                                            {
                                              title: "Total Mandate Amount",
                                              value: currencyFormatter(item?.totalAmountDue||0),
                                            },
                                            {
                                              title: "Total Outstanding",
                                              value: "---",
                                            },
                                            {
                                              title: "Total Debit",
                                              value: "---",
                                            },
                                            {
                                              title: "Duration",
                                              value: "---",
                                            },
                                            {
                                              title: "Start Date",
                                              value: dateFormatter(item?.startDate, false),
                                            },
                                            {
                                              title: "End Date",
                                              value: dateFormatter(item?.endDate, false),
                                            },
                                          ].map((item, idx)=>(
                                            <div key={idx} className="text-[#667085]">
                                              <p className="font-medium">{item?.title}</p>
                                              <p className="font-bold">{item?.value}</p>
                                            </div>
                                          ))
                                        }
                                      </div>
                                      {
                                        !!monoStatus &&
                                        <div>
                                          <p className="font-bold text-[20px] mt-7 mb-2">Mono Status</p>
                                          <div className="grid grid-cols-5 gap-[20px]">
                                            {
                                              [
                                                {
                                                  title: "Approved",
                                                  value: monoStatus?.data?.approved,
                                                },
                                                {
                                                  title: "Ready To Debit",
                                                  value: monoStatus?.data?.ready_to_debit,
                                                },
                                              ].map((item, idx)=>(
                                                <div key={idx} className="text-[#667085]">
                                                  <p className="font-medium mb-1.5">{item?.title}</p>
                                                  <Status
                                                    type={
                                                        item.value===true ? "success"
                                                        : "fail"
                                                    }
                                                    hideDot={true}
                                                    text={
                                                      item.value===true ? "True"
                                                      : "False"
                                                    }  
                                                    addClass="text-sm py-0 px-2 text-center"
                                                  />
                                                </div>
                                              ))
                                            }
                                          </div>
                                        </div>
                                      }
                                      <div className="flex gap-x-10">
                                          {
                                              (breakdown?.breakdown?.length>0) &&
                                              <div>
                                                  <p className="font-bold text-[20px] mt-7 mb-2">Mandate Breakdown</p>
                                                  <div>
                                                      <div className="flex flex-col gap-y-1 text-ee-grey-2 text-sm">
                                                          <div className="grid grid-cols-3 gap-x-10 bg-[#EAECF0] font-bold py-1 px-2.5">
                                                              <p>Due Date</p>
                                                              <p>Amount</p>
                                                              <p>Status</p>
                                                          </div>
                                                          {
                                                              breakdown?.breakdown?.map(item=>( // add key
                                                                  <div key={item._id} className="grid grid-cols-3 gap-x-10 mb-1.5 px-2.5">
                                                                      <p>{dateFormatter(item?.dueDate)}</p>
                                                                      <p>{currencyFormatter(item?.totalAmount || 0)}</p>
                                                                      <div className="flex">
                                                                          <Status
                                                                              type={
                                                                                  item?.repaymentStatus==="pending" || 
                                                                                      item?.repaymentStatus==="scheduled" || 
                                                                                          item?.repaymentStatus==="partially paid" ? "pending"
                                                                                  : item?.repaymentStatus==="paid" ? "success"
                                                                                  : item?.repaymentStatus==="processing" ? "neutral"
                                                                                  : "fail"
                                                                              }
                                                                              hideDot={true}
                                                                              text={capitalizeFirstLetter(item?.repaymentStatus)}  
                                                                              addClass="text-[10px] py-0 px-2 text-center"
                                                                          />
                                                                      </div>
                                                                  </div>
                                                              ))
                                                          }
                                                      </div>
                                                  </div>
                                              </div>
                                          }
                                          {
                                            (breakdown?.banks?.length>0) &&
                                            <div className="min-w-[200px]">
                                                <p className="font-bold text-[20px] mt-7 mb-2">BVN Linked Banks</p>
                                                <div>
                                                    <p className="text-sm font-bold text-ee-grey-2 mb-1.5">Primary Account</p>
                                                    <div className="flex items-center gap-x-2.5">
                                                        <img src={breakdown?.banks?.[0]?.bankLogo} alt="primary_acc" className="rounded w-[20px] h-[20px]" />
                                                        <p className="text-sm folt-bold text-ee-grey-2">{breakdown?.banks?.[0]?.bankName}</p>
                                                        <div className="flex items-center gap-x-1.5">
                                                            <div 
                                                                className={clsx(
                                                                    "rounded-xl py-[2px] px-2.5 text-xs ml-auto",
                                                                    breakdown?.banks?.[0]?.active ? "bg-[#F6FEF9] text-[#54BD95]":"bg-[#FFF4ED] text-[#FF0000]"
                                                                )}
                                                            >
                                                                <p>{breakdown?.banks?.[0]?.active ? "Active":"Inactive"}</p>
                                                            </div>
                                                            {
                                                                // !breakdown?.banks?.[0]?.active &&
                                                                false &&
                                                                <svg width="12" height="12" className="cursor-pointer" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M5.61817 1.47412C6.20383 0.886191 7.10588 0.838528 7.63298 1.36766C8.16008 1.8968 8.11259 2.80235 7.52693 3.39028L6.6786 4.24191M3.81546 5.20001C3.28837 4.67088 3.33585 3.76531 3.92151 3.17738L4.67422 2.42174" stroke="#0046E6" stroke-width="0.525001" stroke-linecap="round"/>
                                                                    <path d="M5.18377 3.79688C5.71087 4.32601 5.66337 5.23156 5.07772 5.81949L4.22938 6.67111L3.38105 7.52277C2.79539 8.1107 1.89333 8.15834 1.36624 7.62921C0.839151 7.10007 0.886632 6.19452 1.47229 5.60659L2.32063 4.75493" stroke="#0046E6" stroke-width="0.525001" stroke-linecap="round"/>
                                                                </svg>
                                                            }
                                                        </div>
                                                    </div>
                                                    {
                                                        (breakdown?.banks?.length>1) &&
                                                        <div>
                                                            <p className="text-sm font-bold text-ee-grey-2 mb-1.5 mt-2.5">Backup Account(s)</p>
                                                            {
                                                                breakdown?.banks?.map((bank, idx)=>(
                                                                    <div 
                                                                        className={clsx(
                                                                            "flex items-center gap-x-2.5 mb-1.5",
                                                                            idx===0 && "hidden"
                                                                        )}
                                                                        key={idx}
                                                                    >
                                                                        <img src={bank?.bankLogo} alt="backup_acc" className="rounded w-[20px] h-[20px]" />
                                                                        <p className="text-sm folt-bold text-ee-grey-2">{bank?.bankName}</p>
                                                                        <div className="flex items-center gap-x-1.5">
                                                                            <div 
                                                                                className={clsx(
                                                                                    "rounded-xl py-[2px] px-2.5 text-xs ml-auto",
                                                                                    bank.active ? "bg-[#F6FEF9] text-[#54BD95]":"bg-[#FFF4ED] text-[#FF0000]"
                                                                                )}
                                                                            >
                                                                                <p>{bank?.active ? "Active":"Inactive"}</p>
                                                                            </div>
                                                                            {
                                                                                // !bank?.active &&
                                                                                false &&
                                                                                <svg width="12" height="12" className="cursor-pointer" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M5.61817 1.47412C6.20383 0.886191 7.10588 0.838528 7.63298 1.36766C8.16008 1.8968 8.11259 2.80235 7.52693 3.39028L6.6786 4.24191M3.81546 5.20001C3.28837 4.67088 3.33585 3.76531 3.92151 3.17738L4.67422 2.42174" stroke="#0046E6" stroke-width="0.525001" stroke-linecap="round"/>
                                                                                    <path d="M5.18377 3.79688C5.71087 4.32601 5.66337 5.23156 5.07772 5.81949L4.22938 6.67111L3.38105 7.52277C2.79539 8.1107 1.89333 8.15834 1.36624 7.62921C0.839151 7.10007 0.886632 6.19452 1.47229 5.60659L2.32063 4.75493" stroke="#0046E6" stroke-width="0.525001" stroke-linecap="round"/>
                                                                                </svg>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                          }
                                      </div>
                                    </div>
                                  </div>
                                }
                              </div>
                            )
                          },
                      ]}
                  />
              </div>
          </div>
      </div>
  )
}