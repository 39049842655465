import React, { useEffect, useState } from "react";
import activity from "images/svg/recova/recova-activity.svg";
import { HiChevronDown } from "react-icons/hi";
import { FaCalendarAlt } from "react-icons/fa";
import LineGraph from "./LineGraph";
import Filter from "components/shared/Filter";
import { currencyFormatter } from "utils/helpers";
import { RecovaService } from "services/recova.service";
import { useDetectClickOutside } from "react-detect-click-outside";
import { useSearchParams } from "react-router-dom";
import cc_logo from "images/creditchek-icon.svg"
 

export default function HomeSection () {
    const [filter, setFilter] = useState({
        view: 'mandates',
        showViewOption: false,
        dayFilter: 'last 7 days',
        showDayFilter: false,
    })
    const [businessActivity, setBusinessActivity] = useState(null)
    const [graphData, setGraphData] = useState([])
    const viewRef = useDetectClickOutside({
      onTriggered: ()=>setFilter({...filter, showViewOption: false})
    })
    const [searchParams] = useSearchParams();

    useEffect(()=>{
      let service = filter.view
      RecovaService.getRecovaActivity(service)
        .then((response) => response.data)
        .then((res) => {
            setBusinessActivity(res.data)
            const formattedGraphData = Object.entries(res.data.graphData).map(([daysRange, count]) => ({
              daysRange,
              count
            }));
            setGraphData(formattedGraphData)
        })
        .catch((err) => {})
    }, [filter.view])

    return (
        <div>
            <div>
                <div className="flex items-end gap-x-2.5">
                    <h1 className="text-2xl font-bold">Recova Activities</h1>
                    <img src={activity} alt="icon" className="w-8" />
                </div>
                
                <div className='flex gap-2.5 mt-8 relative'>
                    <div ref={viewRef}>
                      <div className='flex gap-x-4 items-center justify-between capitalize rounded-[4px] px-2.5 py-1 shadow-md'>
                          <p>{filter.view}</p>
                          <HiChevronDown className='w-[20px] scale-[1.6]' onClick={()=>setFilter({...filter, showViewOption: !filter.showViewOption})} />
                      </div>
                      {
                          filter.showViewOption &&
                          <div className='absolute bg-white w-[100px] top-[40px] p-2.5 cursor-pointer z-50 shadow-md left-0'>
                            {
                              ["mandates", "payouts", "collections", 
                                // "dispute"
                              ].map((item, idx)=>(
                                <p 
                                  key={idx} 
                                  onClick={()=>{
                                    setFilter({
                                      ...filter, 
                                      view: item, 
                                    })
                                  }}
                                  className="capitalize"
                                >{item}</p>
                              ))
                            }
                          </div>
                      }
                    </div>
                    <div className='flex gap-x-4 items-center justify-between rounded-[4px] px-2.5 py-1 shadow-md'>
                        <p className="whitespace-nowrap">Last 7 days</p>
                        <HiChevronDown className='w-[20px] scale-[1.6]' />
                        <div className='flex items-center'>
                            <FaCalendarAlt className='w-[20px] mr-1' />
                            <p className="whitespace-nowrap">May 18 to May 23</p>
                        </div>
                    </div>
                    <div className='flex gap-x-4 items-center justify-between capitalize rounded-[4px] px-2.5 py-1 shadow-md'>
                        <p>Daily</p>
                        <HiChevronDown className='w-[20px] scale-[1.6]' onClick={()=>setFilter({...filter, showViewOption: !filter.showViewOption})} />
                    </div>
                </div>
                <LineGraph data={graphData} graphFor="home" cartesianGridType="line"/>
                <div className="my-10">
                    <div className="flex items-center justify-between">
                        <p className="text-[20px] font-bold">{searchParams.get("filter")||"Top 5"} Businesses</p>
                        <Filter 
                            options={
                                ["Top 5", "Top 10", "Top 15", "Top 20"].map((item) => ({
                                    label: item,
                                    value: item
                                }))
                            }   
                        />
                    </div>
                    <table class="table-auto w-full mt-2.5 mb-[60px]">
                        <thead className="text-sm rounded opacity-50 bg-[#CCDCFF]">
                            <tr>
                                <th className="text-[#06152B] font-normal py-2.5 pl-2.5">ID</th>
                                <th className="text-[#06152B] font-normal py-2.5">Business Name</th>
                                <th className="text-[#06152B] font-normal py-2.5 capitalize">Total {filter.view==="mandates" ? "e-Mandates":filter.view}</th>
                                <th className="text-[#06152B] font-normal py-2.5 pr-2.5 capitalize">Total {filter.view==="mandates" ? "e-Mandates":filter.view} Amount</th>
                                {
                                  filter.view === "mandates" &&
                                  <th className="text-[#06152B] font-normal py-2.5">E-wallet Balance</th>
                                }
                            </tr>
                        </thead>
                        <tbody>
                          {
                            (
                              filter.view === "mandates" ? businessActivity?.topMandateBus
                              : filter.view === "payouts" ? businessActivity?.topPayoutBus
                              : filter.view === "collections" ? businessActivity?.topCollectionBus
                              : []
                            )?.slice(
                              0, 
                              searchParams.get("filter")==="Top 10" ? 10
                                : searchParams.get("filter")==="Top 15" ? 15
                                : searchParams.get("filter")==="Top 20" ? 20
                                : 5
                            )?.map(item => (
                              <tr key={item._id}>
                                  <td className="text-[#06152B] py-2 font-medium pl-2.5">#{item?._id}</td>
                                  <td className="text-[#06152B] py-2 font-medium">
                                      <div className="flex items-center gap-x-1.5">
                                          {/* <div className="w-8 h-8 rounded bg-red-300">
                                          
                                          </div> */}
                                          <img src={cc_logo} alt="logo" className="w-8" />
                                          <span>{item?.businessName}</span>
                                      </div>
                                  </td>
                                  <td className="text-[#06152B] py-2 flex">
                                      <div className="bg-[#0072F9] bg-opacity-20 text-[#3A36DB] py-1 px-2 rounded">
                                        {
                                          filter.view === "mandates" ? item?.numberOfMandates
                                          : filter.view === "payouts" ? item?.numberOfPayouts
                                          : filter.view === "collections" ? item?.numberOfCollections
                                          : 0
                                        }
                                      </div>
                                  </td>
                                  <td className="text-[#06152B] py-2 pr-2.5">
                                    {currencyFormatter(
                                      (
                                        filter.view === "mandates" ? item?.totalMandateValue
                                        : filter.view === "payouts" ? item?.totalPayoutValue
                                        : filter.view === "collections" ? item?.totalCollectedValue
                                        : 0
                                      ) || 0
                                    )}
                                  </td>
                                  {
                                    filter.view === "mandates" &&
                                    <td className="text-[#06152B] py-2 pr-2.5">
                                      {currencyFormatter(item?.eWallet || 0)}
                                    </td>
                                  }
                              </tr>
                            ))
                          }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}