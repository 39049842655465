import React, { useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Back from '../../../../components/back'
import logo from 'images/svg/profile-dummy.svg'
import Loader from '../../../../components/shared/Loader';
import Tabs from 'components/shared/Tabs';
import clsx from 'clsx';
import { currencyFormatter, formatBigNumber, handleRequestErrors } from 'utils/helpers';
import APICalls from './components/APICalls';
import BillableAPICalls from './components/BillableAPICalls';
import Borrowers from './components/Borrowers';
import WalletFunding from './components/WalletFunding';
import AccountsLinked from './components/AccountsLinked';
import FloatRequests from './components/FloatRequests';
import { SalesOpsService } from 'services/salesops.service';
import { FloatService } from 'services/float.service';
import DataBox from 'components/shared/DataBox';
import api from 'images/svg/box/api.svg'
import borrower from 'images/svg/box/borrower.svg'
import bank from 'images/svg/box/bank.svg'
import user from 'images/svg/box/user.svg'
import wallet from 'images/svg/box/wallet.svg'
import walletLightBlue from 'images/svg/box/wallet/walletBlue.svg'
import float from 'images/svg/box/sales/float.svg';
import repaymentAmount from 'images/svg/box/sales/amount.svg';
import outstanding from 'images/svg/box/sales/outstanding.svg';
import overdue from 'images/svg/box/sales/overdue.svg';
import graph from 'images/svg/box/graph.svg'
import graphRed from 'images/svg/box/graphRed.svg'
import Filter from 'components/shared/Filter';
import selectArrow from "images/svg/select-arrow.svg";
import useSingleState from 'hooks/useSingleState';


const Details = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    let params = useParams()
    let navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [boxData, setBoxData] = useState({
        boxFor: "sales-ops",
        item: []
    });
    const [repaymentData, setRepaymentData] = useState({})
    const query = searchParams.get("filter") || "this_month"
    const startDate = searchParams.get("customStart")||""
    const endDate = searchParams.get("customEnd")||""
    const usage = useSingleState(0)

    useEffect(() => {
        setIsLoading(true)
        getUsage()
        SalesOpsService.getBusinessById(params?.id, {
            type: query,
            start: startDate,
            end: endDate,
        })
            .then(response => response.data)
            .then(res => {
                setIsLoading(false)
                if(res.status === 'success'){
                    setBoxData({
                        ...boxData,
                        item: res.data
                    })
                }
            })
            .catch((err) => {
                setIsLoading(false)
                handleRequestErrors(err, false, "120000")
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query])

    useEffect(()=>{
        floatStats();

        searchParams.set('tab', "api-calls");
        setSearchParams(searchParams);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getUsage = () => {
        SalesOpsService.getBusinessUsage(params?.id, {
            type: query,
            start: startDate,
            end: endDate,
        })
            .then(response => response.data)
            .then(res => {
                if(res.status === 'success'){
                    usage.set(res.data)
                }
            })
            .catch((err) => {
                handleRequestErrors(err, false, "120000")
            });
    }

    const floatStats = () => {
        FloatService.repaymentStatsByBusinessId(params?.id)
            .then(response => response.data)
            .then((res) => {
                if(res.status === 'success'){
                    setRepaymentData(res.data)
                }
            })
            .catch((err) => {
                handleRequestErrors(err)
            });
    }

    const filterOptions = [
        { label: "Last 7 days", value: "last_seven_days"},
        { label: "This Month", value: "this_month"},
        { label: "Last Month", value: "last_month"},
        { label: "Last 3 Months", value: "last_three_months"},
        { label: "Last 6 Months", value: "last_six_months"},
        { label: "Last 12 Months", value: "last_twelve_months"},
        { label: "Custom", value: "custom"},
    ]

    return (
        <div>
            <div className="box-border">
                <Back to={()=> navigate("/sales-ops")} />
                <div className='w-full bg-[#EEF3FF] rounded-[18px] my-[2em] mx-0 py-6 px-10 flex items-center justify-between'>
                {
                    isLoading ?
                    <Loader height="150px" /> :
                    <div className='flex justify-between items-center w-full'>
                    <div className='w-[160px] h-[160px] rounded-full border-[4px] border-solid border-white mr-[50px]'>
                        <img className='w-full h-full rounded-full' src={boxData?.item?.businessDetails?.logo ? boxData?.item?.businessDetails?.logo : logo} alt="icon" />
                    </div>
                    <div>
                        <h1 className='text-[30px] font-medium leading-[38px]'>{boxData?.item?.businessDetails?.businessName}</h1>
                        {
                        [
                            {
                            title: "Business ID:",
                            value: params?.id,
                            },
                            {
                            title: "Account Name:",
                            value: boxData?.item?.wallet?.[0]?.vfd_account_name||"Not available",
                            },
                            {
                            title: "Account Number:",
                            value: boxData?.item?.wallet?.[0]?.vfd_account_number||"Not available",
                            },
                            {
                            title: "VFD MFB",
                            value: "",
                            },
                        ].map((item, idx)=>(
                            <p key={idx} className='m-0 font-bold text-base leading-[24px] mb-[0.2rem]'>{item.title} {item.value}</p>
                        ))
                        }
                    </div>
                    {
                        boxData?.item?.businessDetails &&
                        <div className='ml-auto'>
                            {
                                [
                                {
                                    title: "Compliance Status",
                                    value: boxData?.item?.businessDetails?.isComplied ? 'true' : 'false',
                                    bgColor: boxData?.item?.businessDetails?.isComplied ? 'bg-[#47D800]' : 'bg-[#FB2C2C]',
                                },
                                {
                                    title: "App Status",
                                    value: boxData?.item?.appStatus?.[0]?.isLive ? 'true' : 'false',
                                    bgColor: boxData?.item?.appStatus?.[0]?.isLive ? 'bg-[#47D800]' : 'bg-[#FB2C2C]',
                                },
                                {
                                    title: "Wallet Status",
                                    value: boxData?.item?.wallet?.[0]?.vfd_released ? 'Active' : 'Inactive',
                                    bgColor: boxData?.item?.wallet?.[0]?.vfd_released ? 'bg-[#47D800]' : 'bg-[#FB2C2C]',
                                },
                                ].map((item, idx)=>(
                                <div key={idx} className='flex justify-between items-center my-2.5 mx-0'>
                                    <p className='m-0 font-bold text-base leading-[24px] mb-[0.2rem]'>{item.title}</p>
                                    <div
                                    className={clsx(
                                        "w-[65px] h-[20px] flex items-center justify-center rounded-[10px] text-white p-[5px] ml-[15px] text-sm",
                                        item.bgColor
                                    )}
                                    >
                                    {item.value}
                                    </div>
                                </div>
                                ))
                            }
                        </div>
                    }
                    </div>
                }
                </div>
                <div className='flex items-center gap-x-8 mt-[30px] mb-[3em]'>
                    {
                        [
                            {label: "Email", value: boxData?.item?.businessDetails?.email||"---"},
                            {label: "Phone", value: boxData?.item?.businessDetails?.phoneNumber||"---"},
                            {label: "Address", value: boxData?.item?.businessDetails?.address||"---"},
                        ].map((item, idx)=>(
                            <div key={idx} className='font-medium !m-0'>
                                <p className='text-sm leading-[20px] text-[#667085]'>{item.label}</p>
                                <p className='text-base leading-[24px] text-[#344054]'>{item.value}</p>
                            </div>
                        ))
                    }
                    <div className='ml-auto'>
                        <Filter
                            placeHolder="This Month"
                            options={
                                filterOptions.map((item) => ({
                                    label: item.label,
                                    value: item.value
                                }))
                            }
                            containerClass="border-ee-primary text-base text-[#8A8A8A] flex-row-reverse"
                            icon={selectArrow}
                            iconClassName="!w-[10px]"
                            optionItemClass="text-sm text-xs font-semibold p-2 rounded-md m-0 hover:bg-ee-grey-3"
                        />
                    </div>
                </div>
                <div className='grid grid-cols-4 gap-[20px] box-border'>
                    {
                        [
                            {
                                title: "Total billable API Calls",
                                value: formatBigNumber(boxData?.item?.totalBillableApis||0, true),
                                icon: api,
                            },
                            {
                                title: "Total Borrowers",
                                value: formatBigNumber(boxData?.item?.totalBorrowers||0, true),
                                icon: borrower,
                            },
                            {
                                title: "Wallet Funding",
                                value: currencyFormatter(boxData?.item?.filteredWalletFunding||0),
                                icon: wallet,
                                slot: (
                                    <div className='flex items-center justify-between'>
                                        <div className='flex items-center gap-x-0.5'>
                                            <img src={boxData?.item?.walletPercentage < 0 ? graphRed : graph} alt="icon" />
                                            <span
                                            className={clsx(
                                                "text-sm",
                                                boxData?.item?.walletPercentage < 0 ? 'text-[#FA582E]':"text-[#4BDE97]"
                                            )}
                                            >
                                            {Math.abs(boxData?.item?.walletPercentage||0)}%
                                            </span>
                                        </div>
                                        <p className='text-sm text-[#B9B9B9]'>{filterOptions.find(x => x.value===query)?.label || "Custom"}</p>
                                    </div>
                                )
                            },
                            {
                                title: "Account Balance",
                                value: currencyFormatter(boxData?.item?.wallet?.[0]?.balance||0),
                                icon: walletLightBlue,
                            },
                            {
                                title: "Total Bank Accounts Linked",
                                value: boxData?.item?.totalAccountLinked||0,
                                icon: bank,
                            },
                            {
                                title: "Business Team Users",
                                value: boxData?.item?.totalUsers||0,
                                icon: user,
                            },
                            {
                                title: "Total Wallet Funding", // new
                                value: currencyFormatter(boxData?.item?.totalWalletFunding||0),
                                icon: wallet,
                            },
                            {
                                title: "Usage",
                                value: currencyFormatter(usage.get||0),
                                icon: api,
                            },
                            {
                                title: "Total Float Requests",
                                value: repaymentData?.totalRequest||0,
                                icon: float,
                                hide: repaymentData?.totalRequest<1,
                            },
                            {
                                title: "Active Floats",
                                value: repaymentData?.totalActiveLoans||0,
                                icon: float,
                                hide: repaymentData?.totalRequest<1,
                            },
                            {
                                title: "Total Disbursed Amount",
                                value: currencyFormatter(repaymentData?.totalDisbursedLoanAmount||0),
                                icon: repaymentAmount,
                                hide: repaymentData?.totalRequest<1,
                            },
                            {
                                title: "Total Open Loan Amount",
                                value: currencyFormatter(repaymentData?.totalLoanAmount||0),
                                icon: repaymentAmount,
                                hide: repaymentData?.totalRequest<1,
                            },
                            {
                                title: "Total Outstanding",
                                value: currencyFormatter(repaymentData?.totalOutstanding||0),
                                icon: outstanding,
                                hide: repaymentData?.totalRequest<1,
                            },
                            {
                                title: "Total Outstanding Principal",
                                value: currencyFormatter(repaymentData?.totalOutstandingPrincipal||0),
                                icon: repaymentAmount,
                                hide: repaymentData?.totalRequest<1,
                            },
                            {
                                title: "Total Overdue",
                                value: currencyFormatter(repaymentData?.totalDue||0),
                                icon: overdue,
                                hide: repaymentData?.totalRequest<1,
                            },
                            {
                                title: "Recova e-wallet Balance",
                                value: currencyFormatter(boxData?.item?.recovaEwallet?.balance||0),
                                icon: wallet,
                            },
                        ].map(item => (
                            <div
                                key={item.title}
                                className={clsx(item.hide && 'hidden')}
                            >
                                <DataBox
                                    title={item.title}
                                    icon={<img src={item.icon} alt={item.title} className='w-[20px]' />}
                                    value={item.value}
                                    className="!shadow-[0px_13.3657px_44.5523px_rgba(160,163,189,0.5)]"
                                    slot={item?.slot}
                                />
                            </div>
                        ))
                    }
                </div>
                <div className='mt-10'>
                    <div>
                        <Tabs 
                            tabs={[
                                {
                                    label: "API Calls",
                                    key: "api-calls"
                                },
                                {
                                    label: "Billable API Calls",
                                    key: "billable-api-calls"
                                },
                                {
                                    label: "Borrowers Onboarded",
                                    key: "borrowers"
                                },
                                {
                                    label: "Wallet Funded",
                                    key: "wallet-funded"
                                },
                                {
                                    label: "Account Linked",
                                    key: "account-linked"
                                },
                                {
                                    label: "Float Requests",
                                    key: "float-requests"
                                },
                            ]}
                            useAsLink={false}
                            clearSearch
                        />
                    </div>
                    {
                        searchParams.get("tab") === 'api-calls' ?  <APICalls businessName={boxData?.item?.businessDetails?.businessName} query={query} />
                        : searchParams.get("tab") === 'billable-api-calls' ? <BillableAPICalls businessName={boxData?.item?.businessDetails?.businessName} query={query} />
                        : searchParams.get("tab") === 'borrowers' ? <Borrowers businessName={boxData?.item?.businessDetails?.businessName} query={query} />
                        : searchParams.get("tab") === 'wallet-funded' ? <WalletFunding businessName={boxData?.item?.businessDetails?.businessName} query={query} />
                        : searchParams.get("tab") === 'account-linked' ? <AccountsLinked businessName={boxData?.item?.businessDetails?.businessName} query={query} />
                        : searchParams.get("tab") === 'float-requests' ? <FloatRequests businessName={boxData?.item?.businessDetails?.businessName} query={query} />
                        : null
                    }
                </div>
            </div>
        </div>
    )
}

export default Details